import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.main`

  background-color: ${({ theme }) => theme.bg.fifth};
  padding: 80px 0;

  
  ${mediaQuery.desktop`
    padding: 150px 0;
  `}

  .container {
    display: block;

    ${mediaQuery.desktop`
      column-count: 2;
      column-gap: 80px;
    `}
  }

  header {
    display: inline-block;
    width: 100%;
    
    margin-bottom: 64px;
    text-align: center;

    ${mediaQuery.desktop`
      text-align: left;
    `}

    h1 {
      font-size: 26px;
      color: ${({ theme }) => theme.font.second};
      margin-bottom: 20px;
      font-weight: 600;
      max-width: 185px;
      margin: 0 auto 20px;
      
      ${mediaQuery.desktop`
        font-size: 38.5px;
        max-width: inherit;
      `}
    }

    p {
      font-size: 16px;
      color: ${({ theme }) => theme.font.primary};
    }
  }

  .container.Consigned__next-page {  
    justify-content: center;
    padding-top: 63px;

    ${mediaQuery.desktop`
       display: flex;
       padding-top: 150px;
    `}

    button {
      font-weight: 600;
      text-transform: uppercase;
      min-height: 53px;
      width: 100%;

      ${mediaQuery.desktop`
       display: flex;
       width: auto;
    `}
    }
  }
`;
