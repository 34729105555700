import React from "react";
import { navigate } from "gatsby";

import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import ListServices from "../../components/listServices";

import { Container } from "./styles";

import bgConsigned from "../../assets/images/bg-consigned.jpg";
import bgConsignedMobile from "../../assets/images/bg_consigned-mobile.jpg";
import { useWindowSize } from "../../hooks/resizeWindow";

import contentConsignedAsAService from "../../content/pages/ConsignedAsAService";

const ConsignedAsAService = () => {
  const size = useWindowSize();

  function handleNextPage() {
    navigate("/contato");
  }

  React.useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Banner
        title="Consignado as a Service"
        text="Nossa expertise disponível para a sua necessidade. Cuidamos da sua operação de Crédito Consignado Privado para você explorar novos negócios com tranquilidade e foco."
        backgroundImg={size.width > 800 ? bgConsigned : bgConsignedMobile}
        classJumbotron="info-banner col-md-7"
      />

      <section id="consigned-as-service">
        <Container>
          <div className="container">
            <header>
              <h1>Você escolhe como operar</h1>
              <p>
                Utilizando módulos ou uma solução completa, cobrimos todo o
                processo de concessão de crédito para sua empresa.
              </p>
            </header>

            {contentConsignedAsAService.cards.map((card, index) => (
              <ListServices
                key={index}
                title={card.title}
                text={card.text}
                list={card.list}
              />
            ))}
          </div>
          <section className="container Consigned__next-page">
            <Button onClick={handleNextPage}>Fale conosco</Button>
          </section>
        </Container>
      </section>
    </Layout>
  );
};

export default ConsignedAsAService;
