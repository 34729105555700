import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.article`
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  padding: 30px;
  background: ${({ theme }) => theme.bg.primary};
  box-shadow: 0px 3px 6px #00000029;

  ${mediaQuery.desktop`
    margin-top: 65px;
    padding: 80px;
  `}

  .text-wrapper {
    width: 100%;

    ${mediaQuery.desktop`
      width: auto;
      margin-left: inherit;
    `}
  }

  h1 {
    color: ${({ theme }) => theme.font.third};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;

    ${mediaQuery.desktop`
      font-size: 24px;
    `}

    &:before {
      content: '';
      margin-bottom: 15px;
      display: block;
      width: 40px;
      height: 5px;
      background: ${({ theme }) => theme.font.fourth};
    }

  }

  p {
    color: ${({ theme }) => theme.font.primary};
    font-size: 16px;
  }

  li {
    color: ${({ theme }) => theme.font.primary};
    margin-bottom: 10px;
    padding-left: 1.235em;
    font-size: 16px;

    ${mediaQuery.desktop`
      margin-bottom: 20px;
    `}

    &:before {
      content: "●";
      color: #CCDD00;
      font-weight: bold;
      display: inline-block;
      width: 1.235em;
      margin-left: -1.325em;
    }
  }

  &:nth-child(5) {
    opacity: 0;
    display: none;

    ${mediaQuery.desktop`
      display: block
    `}
  }
`;
