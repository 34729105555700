import React from "react";

import { Container } from "./styles";

import { CardProps } from "./interface";

const Card: React.FC<CardProps> = ({ title, text, list }) => {


  return (
    <Container>
      <div className="text-wrapper">
        <h1>{title}</h1>
        {text ? <p>{text}</p> : null}
        <ul>
          {list?.map((item, index) => (
            <li key={index}>{item.text}</li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default Card;
