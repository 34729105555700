

export default {
  cards: [
    {
      title: 'Motor de Crédito',
      text:
        'Análise de forma rápida e automática dos seus parâmetros de crédito.',
    },
    {
      title: 'Atendimento Multiplataforma',
      text:
        'A contratação e a formalização das operações de crédito são feitas de forma simples e rápida pelo APP Zipdin, e a negociação e validação pela nossa Central de Atendimento.',
    },
    {
      title: 'Gestão de Carteira de Crédito',
      list: [
        {text:'Controle da cessão, baixas da carteira e conciliação, incluindo apropriação diária da receita e PDD'},
        {text:'Fazemos a cobrança e a conciliação das parcelas descontadas dos funcionários junto às empresas conveniadas, e também em casos de inadimplência'}
      ],
    },
    {
      title: '',
      text: '',
    },
    {
      title: 'Crédito e Cobrança',
      text: 'Apoio no desenvolvimento das políticas de crédito e cobrança para as empresas conveniadas e funcionários. Além da disponibilidade de ferramentas para análise de crédito e estudo de viabilidade comercial dos convênios.',
    },
    {
      title: 'Servicing',
      list: [
        {text:'Gestão de limite de crédito por convênio'},
        {text:'Esteira de crédito com consulta a dados em bureau de crédito'},
        {text:'Atendimento Multiplataforma'},
        {text:'Sistema para gerenciar contratos, faturas e integrar bases'},
        {text:'Conferência de margem e documentos'}
      ],
    },
    {
      title: 'Formalização Bancária e Cessão de Crédito',
      text: 'Viabilizamos a formalização bancária das operações e a cessão para o veículo de investimento de sua indicação.',
    },
    {
      title: 'Opção White Label',
      text: 'Sua marca em todas as etapas do processo.',
    },
  ]
};
